export const Logo = ({ className }: { className: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 648 220.3"
    className={className}
    fill="currentColor"
  >
    <path d="M648 154.8c-.9 3.1-3 4.2-6.2 4.2-9-.1-17.9-.1-26.8-.1-4.6 0-5.3-1.1-5.5-5.7-.1-4.6-.7-9.1-1.2-13.7 0-.6-.9-1.6-1.3-1.6-.8 0-1.7.5-2.3 1.1-2.3 2.5-4.2 5.4-6.7 7.8-8.1 7.6-18 11.4-28.8 13.2-18 3.1-35.3 1-52-6.5-11.6-5.2-19.9-14-26.3-24.7-18.1-30.2-18.8-61.3-2.4-92.1C499.7 15.8 519 5.2 542.1 1.4c15.1-2.5 30-1.1 44.2 5.5 6.3 3 11.7 7.1 16.5 12.1.8.8 1.8 1.4 2.8 2 .2-.1.5-.3.8-.4.3-3.7.8-7.4.8-11.1 0-4.8.9-5.7 5.7-5.7h29.6c2.8 0 4.7.7 5.5 3.5v30.1c-.1.8-.4 1.6-.4 2.4v98c0 .8.3 1.6.4 2.4v14.6Zm-42-73.4h-.4c-.2-3.1-.3-6.2-.7-9.2-2.2-18.2-11-31.2-29.1-36.9-21.8-6.8-43.5 3-51.5 23.7-4.8 12.4-4.8 25.3-2.1 38.2 1.5 7.3 4.6 14 9.9 19.4 11.8 12 26 14.4 41.8 10.5 15.4-3.8 25.1-13.5 29.3-28.6 1.5-5.5 1.9-11.3 2.8-17ZM226.7 117c2.1-5 4.5-10 6.4-15.1 9.4-26.2 18.8-52.4 28.1-78.6 2-5.5 4-11 5.9-16.5.7-1.9 1.8-2.9 3.8-2.9 13 0 26 0 38.9.1.2 0 .5 0 1.1.2-.3 1-.6 1.9-1 2.8-16.7 41.5-33.5 83.1-50.2 124.6-5.7 14-11.1 28.1-17 42.1-4.5 10.8-10.7 20.6-19 29-11.1 11.2-24.9 16.1-40.3 17.4-10.5.9-20.7 0-30.7-3.2-3.4-1.1-3.6-1.4-2.5-4.8 2.6-8.5 5.2-16.9 7.8-25.4 1.1-3.7 2.1-4.3 5.9-3.2 9.3 2.6 18.5 2.7 27.5-1.4 10.5-4.8 15.5-17.3 11.2-28.1-18.6-45.7-37.2-91.5-55.8-137.2-1.1-2.8-2.4-5.5-3.5-8.3-1.5-3.5-.9-4.6 2.9-4.6h36.9c12.3 0 4.1.6 5.2 3.8 8.4 24.2 16.8 48.5 25.1 72.7 4 11.5 7.9 23.1 11.9 34.6.2.6.5 1.2.7 1.9.2 0 .4.1.7.2Zm133-91.9c1.8-2 3.7-4 5.4-6.1 9.2-11.1 21.4-16.6 35.5-18 10.7-1 21.4-.7 31.8 2.9 19.8 6.9 33.2 25.6 33.3 46.5.2 34.3.3 68.7.4 103 0 5-.4 5.4-5.4 5.4h-30.6c-4.4 0-5.5-1-5.5-5.5 0-12.4.3-24.7.3-37.1 0-16.5 0-32.9-.4-49.4 0-4.7-.9-9.5-2.1-14.1-3.2-12.1-10.7-17.8-23.2-18.3-20-.8-34.4 12.6-35.6 32.6-1.7 27.7-.5 55.3-.6 83 0 1.7 0 3.3-.1 5-.1 2.8-.8 3.5-3.7 3.6-3.3.1-6.7 0-10 0h-23.3c-7.7 0-5.2-.7-5.2-5.2V9.5c0-5.1.5-5.6 5.5-5.7h27.9c3.4 0 4.2.7 4.4 4 .3 5.7.6 11.3.9 17 .2 0 .4.2.6.2Z" />
    <path d="M150.7 38.7c-7.3-12.2-16.1-23.1-29.2-29.2C99.7-.7 77.1-3.1 53.9 4.5 37.4 9.9 23.2 18.9 13.7 33.8.5 54.5-2.9 77 2.4 100.9c5 22.6 17.5 39.7 37.9 50.8 13.3 7.3 27.9 9.3 43 9.9 5.9-.4 11.9-.5 17.7-1.3 19.2-2.9 34.5-12.5 46.9-27.2 1.3-1.5 1.2-2.7-.4-4-7.9-6.2-15.7-12.5-23.6-18.7-1.9-1.5-3.6-1.5-5.4.2-1.3 1.2-2.5 2.5-3.8 3.6-11 9.8-23.7 14-38.5 11.6-15.5-2.5-26-10.8-30.5-26.1-4.4-14.9-3.4-29.6 2-44 2.8-7.4 7.4-13.6 14.7-17.1 14.8-7 29.3-6.5 43 3.2 4.6 3.2 8 7.5 10.8 12.4 1.1 2 2.5 3.5 5 2.3 9.4-4.4 18.7-8.9 28.1-13.4 2-.9 2.4-2.5 1.3-4.3Z" />
  </svg>
)
