"use client"

import "@cyna/web/i18n"

import { HOME_PATH } from "@cyna/common/routes"
import { Link } from "@cyna/components/base/Link"
import { Typo } from "@cyna/components/base/Typo"
import { Logo } from "@cyna/components/business/Logo"
import { Button } from "@cyna/components/ui/button"
import { useTranslation } from "react-i18next"

const Error = () => {
  const { t } = useTranslation()

  return (
    <>
      <header className="sticky top-0 z-20 flex h-16 min-h-16 items-center border-b p-3">
        <Link path={HOME_PATH}>
          <Logo className="text-primary h-6" />
        </Link>
      </header>
      <main className="mx-auto flex h-full min-w-96 max-w-xl grow flex-col gap-4 p-4 xl:px-0">
        <div className="bg-background mx-auto flex w-full max-w-4xl items-center justify-between gap-4 px-4 py-4 lg:px-0">
          <Typo as="h1">
            <p> {t("common:errors.global.title")}</p>
          </Typo>
        </div>
        <Typo>
          <p>{t("common:errors.global.subtitle")}</p>
        </Typo>
        <Button asChild className="w-fit">
          <Link path={HOME_PATH}>{t("public:notFound.cta")}</Link>
        </Button>
      </main>
    </>
  )
}

export default Error
